import { createRouter, createWebHistory } from "vue-router";
import store from "./../store/index";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/home/Home.vue"),
  },
  {
    path: "/citizen-charter",
    name: "CitizenCharter",
    component: () => import("../views/citizenCharter/CitizenCharterIndex.vue"),
  },
  {
    path: "/citizen-charter/:id",
    name: "CitizenCharterDetail",
    component: () => import("../views/citizenCharter/CitizenCharterDetail.vue"),
  },
  {
    path: "/e-profile",
    name: "Eprofile",
    component: () => import("../views/eprofile/EprofileIndex.vue"),
  },
  {
    path: "/sectors",
    name: "Sectors",
    component: () => import("../views/sectors/SectorIndex.vue"),
  },
  {
    path: "/document-library",
    name: "DocumentLibrary",
    component: () =>
      import("../views/documentLibrary/DocumentLibraryIndex.vue"),
  },
  {
    path: "/point-of-interest",
    name: "Poi",
    component: () => import("../views/pointOfInterest/poi.vue"),
  },
  {
    path: "/document-library/:id",
    name: "DocumentLibraryDetail",
    component: () =>
      import("../views/documentLibrary/DocumentLibraryDetail.vue"),
  },
  {
    path: "/policies",
    name: "Policies",
    component: () => import("../views/policies/Policies.vue"),
  },
  {
    path: "/decisions",
    name: "Decisions",
    component: () => import("../views/decisions/Decisions.vue"),
  },
  {
    path: "/budget",
    name: "Budget",
    component: () => import("../views/budget/Budget.vue"),
  },
  {
    path: "/sectors/:type",
    name: "Sector",
    component: () => import("../views/sectors/SectorIndex.vue"),
    beforeEnter: (to, from, next) => {
      let options = ["health", "education", "agriculture", "water_sanitation"];

      if (to.params.type && options.includes(to.params.type)) {
        next();
      } else {
        next("404");
      }
    },
  },
  {
    path: "/grievance",
    name: "Grievance",
    component: () => import("../views/grievance/Grievance.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../views/shared/404.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/privacyPolicy/privacy-policy.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (!to.hash) {
    store.dispatch("setLoading", true);
  }
  next();
});

export default router;
